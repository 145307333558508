html, body {
    height: 100%;
    margin: 0;
}

.app-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-header {
    padding: 0px;
    background: white;
    text-align: center;
    width: 100%;
}

.auth-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.auth-info p {
    margin: 0;
    margin-right: 10px;
}

.auth-info button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #0078d4;
    color: white;
    cursor: pointer;
}

.auth-info button:hover {
    background-color: #005a9e;
}
.container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the spacing between items */
}

.item {
    width: calc(30% - 1px); /* Adjust width to make two items per row */
}